







































































































import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";
import { AxiosError, AxiosResponse } from "axios";
import BtnRequestPatients from "./buttons/BtnRequestPatients.vue";
import PatientFilter from "./PatientFilter.vue";
import Patient from "@/models/Patient";
import { debounce } from "lodash";

export default Vue.extend({
  name: "crm-patients",
  components: {
    NotRecords,
    Errors,
    BtnRequestPatients,
    /*   PatientFilter, */
  },
  data() {
    return {
      signature: new Blob(),
      loadingSignStep1: false,
      showErrors: false,
      options: {},
      reqrange: {
        limit: 100,
        offset: 0,
      },
      errorMessage: "",
      loadingSendStep: false,
      footerProps: { "items-per-page-options": [10, 15, 25, 50, 100] },
      headers: [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "start", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "start",
          sortable: false,
        },
        // { text: "", value: "actions", sortable: false },
        // { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      // The uuid of the worker to remove
      currentPatient: null,
      dialogEdit: false,

      dialogDocument: false,
      dialogSignStep1: false,

      query: null,
      doctorUuid: null,
      sellerUuid: null,
      createdByUuid: null,
      loadingQuery: false,
      isAdmins: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", [
      "patients",
      "loading",
      "totalpatient",
      "doctors",
      "agents",
    ]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
    ]),
    ...mapState("crmEmployeeModule", ["workers", "coordinators"]),

    disableWhileLoading(): boolean {
      return this.loading || this.loadingSignStep1 || this.loadingSendStep;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getPatients();
      }
    },
    query(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 3 || val == null) {
        this.getPatients();
      }
    },
  },
  async mounted() {
    if (this.isSuper || this.isAdmin) {
      this.isAdmins = true;
      await this.actGetEmployees();
      await this.actGetCoordinators();
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListPatients",
      "actRemovePatient",
      "actGetPatientAgents",
      "actGetPatientDoctors",
      "actFilterPatient",
    ]),
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),
    ...mapMutations(["mutReqRange"]),
    getPatients() {
      const { page, itemsPerPage }: any = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.reqrange = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(this.reqrange);
      if (
        this.query == null &&
        this.doctorUuid == null &&
        this.sellerUuid == null &&
        this.createdByUuid == null
      ) {
        this.actListPatients(this.reqrange);
      } else {
        this.actFilterPatient({
          query: this.query,
          doctorUuid: this.doctorUuid,
          range: this.reqrange,
          sellerUuid: this.sellerUuid,
          createdByUuid: this.createdByUuid,
        });
      }
    },

    setPatientToSendDocument(patient: any) {
      this.currentPatient = patient;
      this.dialogDocument = true;
    },
    sendStep(step: number, patientUuid: string): void {
      this.loadingSendStep = true;
      getAPI.post(`/patient/sendstep${step}/${patientUuid}`).then(() => {
        notifyInfo("The documents have been sended");
        this.loadingSendStep = false;
      });
    },
    showSignDialog(patient: any) {
      this.signature = new Blob();
      this.currentPatient = patient;
      this.dialogSignStep1 = true;
    },
    signAdminStep1() {
      const formData = new FormData();

      this.loadingSignStep1 = true;
      formData.append(
        "firma",
        this.signature,
        `signature-${(this as any).currentPatient.uuid}`
      );
      getAPI({
        method: "post",
        url: "/patient/uploadAdminSign/" + (this as any).currentPatient.uuid,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        })
        .catch((error: any) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        });
    },
    showDetails(patient: any) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },
  },
});
